import React from "react"

import { Video as BaseVideo } from "@bounce/web-components"

export const Video = () => {
  return (
    <div className="text-center text-primary-900">
      <div className="mx-auto px-8 py-20 md:max-w-4xl md:px-16 md:py-26">
        <h2 className="title2 mb-3">We make life lighter for everyone</h2>
        <p className="paragraph1">
          We believe your days shouldn’t be planned around your stuff. That’s why we’re powering the global storage
          network that’s everywhere you are, so anything you need is just a tap away.
        </p>
      </div>
      <div className="mx-auto flex w-full max-w-screen-xl flex-col items-center justify-center px-8 md:px-16">
        <BaseVideo
          uuid="9c67c127-1b8b-4a91-b3de-420d8c5fe78e"
          thumbnailUuid="ab0ad14b-5336-4472-bca8-c18e1e981ff2"
          className="overflow-clip rounded-2xl bg-primary-100"
        />
      </div>
      <div className="mx-auto px-8 py-20 md:max-w-4xl md:px-16 md:py-26">
        <h2 className="title2 mb-3">We change how people live</h2>
        <p className="paragraph1">
          Our scrappy, close-knit team is all about maximizing impact and having fun while doing it. We’re creating the
          remote control for people’s things so they can make the most of every opportunity.
        </p>
      </div>
    </div>
  )
}
