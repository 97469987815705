import { useInView } from "framer-motion"
import groupBy from "lodash/groupBy"
import React, { useRef } from "react"

import { DEFAULT_LANGUAGE } from "@bounce/i18n"

import { EmployeeTestimonials } from "./components/EmployeeTestimonials/EmployeeTestimonials"
import { Hero } from "./components/Hero/Hero"
import { Investors } from "./components/Investors/Investors"
import { OpenRoles } from "./components/OpenRoles/OpenRoles"
import { Press } from "./components/Press/Press"
import { Values } from "./components/Values/Values"
import { Video } from "./components/Video"
import { WhereWeWork } from "./components/WhereWeWork/WhereWeWork"
import { Layout } from "@/components/Generic/Layout/Layout"
import type { AshbyRoleEntry } from "@/libs/ashby"
import { useUrl } from "@/libs/router"

export type CareersScreenProps = {
  roles?: AshbyRoleEntry[]
}

const scrollToRoles = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  ev.preventDefault()

  document.getElementById("roles")?.scrollIntoView({ behavior: "smooth" })
}

const CareersScreen = ({ roles }: CareersScreenProps) => {
  const { absolutePath } = useUrl()

  const metaTitle = "Life at Bounce - Explore open roles"
  const metaDescription =
    "Life at Bounce - Join our global team in the mission to free everyone from their physical belongings"

  const heroRef = useRef<HTMLDivElement>(null)

  const isInView = useInView(heroRef, { margin: "-76px" })

  return (
    <Layout
      headProps={{
        title: metaTitle,
        description: metaDescription,
        url: absolutePath,
      }}
      navBarClassName={isInView ? "!bg-lilac-100" : undefined}
      languages={[DEFAULT_LANGUAGE]}>
      <Hero ref={heroRef} ctaHref="#roles" ctaOnClick={scrollToRoles} />
      <Video />
      <Values />
      {/* The roles id is a bit upper of the component to keep the roles section title visible */}
      <div id="roles" />
      <OpenRoles className="mt-section" openRoles={groupBy(roles, "teamName")} />
      <WhereWeWork />
      <Investors />
      <EmployeeTestimonials />
      <Press />
    </Layout>
  )
}

export default CareersScreen
