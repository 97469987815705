import classNames from "classnames"
import type { MotionValue } from "framer-motion"
import { useScroll, useTransform, motion } from "framer-motion"
import React, { useRef } from "react"

import { theme } from "@bounce/theme"

import styles from "./assets/values.module.css"

type ValueProps = {
  title: string | React.ReactNode
  description: string
  scale: MotionValue<number>
  range: [number, number]
}

const VALUES = [
  {
    title: (
      <>
        <span className="inline-block">Velocity is</span> <span className="inline-block">priority #1</span>
      </>
    ),
    description: "The faster we ship, the faster we learn, and the faster we grow.",
  },
  {
    title: "Be experiment-driven",
    description: "Hypothesis-driven thinking leads to faster learning. Keep things simple and keep experimenting.",
  },
  {
    title: "Earn trust with every decision",
    description: "Have a solid decision-making process. Create trust and get ever-increasing autonomy.",
  },
  {
    title: "Own the outcome",
    description: "We're all owners and leaders. It's OK to step outside your swim lane to make the company better.",
  },
  {
    title: "Think at scale",
    description: "Obsess over the impact that has no ceiling. Choose scale over perfection.",
  },
  {
    title: "Be fast. Be scrappy",
    description: "Don't wait for someone else to solve your (our) problems. Think outside the box.",
  },
  {
    title: "Celebrate results and failures",
    description: "Reinforce our great work by celebrating it. A fast mistake means quick learning!",
  },
]

const ValueItem = ({ scale, range, title, description }: ValueProps) => {
  const sizeScale = useTransform(scale, range, [1, 0.9])
  const colorScale = useTransform(scale, range, [theme.color("lilac-100"), theme.color("lilac-200")])

  return (
    <motion.div
      className="sticky top-80 flex h-56 grow origin-top flex-col justify-between gap-y-2 rounded-xl bg-lilac-100 px-10 py-8 text-cranberry-800"
      style={{ scale: sizeScale, backgroundColor: colorScale }}>
      <h4 className="title2">{title}</h4>
      <p className="paragraph2">{description}</p>
    </motion.div>
  )
}

export const Values = () => {
  const container = useRef(null)
  const signal = useRef(null)

  const { scrollYProgress } = useScroll({
    target: signal,
    offset: ["start 320px", "end 544px"],
  })

  return (
    <div className="relative mx-auto w-full bg-cranberry-800 text-lilac-200">
      <div className={classNames("absolute w-full", styles.valuesBgParent)}>
        <div className={classNames("fixed w-full bg-cranberry-700", styles.valuesBg)}></div>
      </div>
      <div
        ref={container}
        className="relative mx-auto grid grid-cols-1 px-8 py-16 md:flex md:max-w-screen-xl md:flex-row">
        <h2 className="title-display sticky top-36 mb-12 text-lilac-100 md:hidden">
          What <br /> we value
        </h2>
        <div className="mb-12 hidden md:my-[20vh] md:block md:basis-1/2">
          <h2 className="title-display sticky top-36 text-lilac-100 md:top-80">
            What <br /> we value
          </h2>
        </div>
        <div ref={signal} className="grid grid-cols-1 gap-y-4 md:my-[20vh] md:basis-1/2">
          {VALUES.map((value, index) => {
            const each = 1 / (VALUES.length - 1)

            return (
              <ValueItem scale={scrollYProgress} range={[index * each, (index + 1) * each]} key={index} {...value} />
            )
          })}
        </div>
      </div>
    </div>
  )
}
