import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { Autoplay } from "swiper/modules"
import type { SwiperRef } from "swiper/react"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"

import type { TestimonialProps } from "../Testimonial/Testimonial"
import { Testimonial } from "../Testimonial/Testimonial"

export type TestimonialSliderProps = {
  items: TestimonialProps[]
  className?: string
}

export const TestimonialSlider = ({ items, className }: TestimonialSliderProps) => {
  const isInteractive = items.length > 1
  const swiperRef = useRef<SwiperRef>(null)

  return (
    <div className={className}>
      <Swiper
        ref={swiperRef}
        enabled={isInteractive}
        spaceBetween={18}
        modules={[Autoplay]}
        autoplay={{ delay: 8000 }}
        loop
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}>
        {items.map((props, i) => (
          <SwiperSlide key={`testimonial-${i}`}>
            <Testimonial {...props} isInteractive={isInteractive} />
          </SwiperSlide>
        ))}
        {isInteractive && <SliderButtons totalItems={items.length} />}
      </Swiper>
    </div>
  )
}

const SliderButtons = ({ totalItems }: { totalItems: number }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const swiper = useSwiper()

  useEffect(() => {
    swiper.on("slideChange", () => {
      setActiveIndex(swiper.realIndex)
    })
    return () => swiper.off("slideChange")
  }, [swiper])

  return (
    <div className="mt-6 flex justify-center gap-x-3">
      {new Array(totalItems).fill(null).map((_, i) => {
        return (
          <button
            key={i}
            className={classNames(
              "h-8 w-8 rounded-full",
              activeIndex === i ? " bg-primary-700" : "scale-75 bg-primary-200",
            )}
            onClick={() => swiper.slideToLoop(i)}
          />
        )
      })}
    </div>
  )
}
