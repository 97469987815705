import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"

export type VideoProps = {
  uuid: string
  thumbnailUuid?: string
  captionSrc?: string
  onPlay?: () => void
  className?: string
  shouldPause?: boolean
  shouldPlay?: boolean
}

export const Video = ({ uuid, className, thumbnailUuid, captionSrc, onPlay, shouldPause }: VideoProps) => {
  const [showControls, setShowControls] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (shouldPause && videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }, [shouldPause])

  useEffect(() => {
    if (shouldPause && videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }, [shouldPause])

  return (
    <div
      className={classNames("cursor-pointer", className)}
      onClick={() => {
        if (showControls) return
        setShowControls(true)
        if (videoRef.current?.paused) {
          videoRef.current.load()
          videoRef.current.play()
          onPlay && onPlay()
        }
      }}>
      <video
        ref={videoRef}
        controls={showControls}
        preload="true"
        poster={thumbnailUuid ? `https://cdn.usebounce.com/${thumbnailUuid}/` : undefined}>
        <source src={`https://cdn.usebounce.com/${uuid}/video/-/size/1920x/-/format/mp4/`} type="video/mp4" />
        <source src={`https://cdn.usebounce.com/${uuid}/video/-/size/1920x/-/format/webm/`} type="video/webm" />
        {!!captionSrc && <track default kind="captions" srcLang="en" src={captionSrc} />}
        You browser does not support the <code>video</code> element. However, you can
        <a href={`https://cdn.usebounce.com/${uuid}/video.mp4`}>download</a>
        the MP4 version of the video.
      </video>
    </div>
  )
}
