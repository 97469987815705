import { TestimonialSlider } from "@bounce/web-components"

import styles from "./assets/slide_override.module.css"

import eslam from "./assets/image-quotes-eslam.jpg"
import lennart from "./assets/image-quotes-lennart.jpg"
import madalena from "./assets/image-quotes-madalena.jpg"
import marco from "./assets/image-quotes-marco.jpg"
import miguel from "./assets/image-quotes-miguel.jpg"

const TESTIMONIALS = [
  {
    key: "miguel",
    src: miguel,
    name: "Miguel R.",
    nameSubtitle: "Product Engineer",
    text: "The team is supportive, passionate, highly talented, and kind. We genuinely connect, enjoy, and have real fun working together, and it’s rare to find that kind of camaraderie in a workplace!",
  },
  {
    key: "madalena",
    src: madalena,
    name: "Madalena T.",
    nameSubtitle: "Strategic Account Manager",
    text: "I get to work on innovative projects, take real ownership, and collaborate with some of the most creative and talented people I’ve ever met. The fast pace keeps things exciting and the sense of community makes me feel like I’m part of something truly special.",
  },
  {
    key: "lennart",
    src: lennart,
    name: "Lennart S.",
    nameSubtitle: "Product Engineer",
    text: "People don't just show up every day because it's their job, but because they want to make an impact in how other people travel and explore. Everyone cares about their work and is genuinely excited about building something together.",
  },
  {
    key: "eslam",
    src: eslam,
    name: "Eslam S.",
    nameSubtitle: "Data Scientist",
    text: "I love working at Bounce because of the opportunity to take ownership of impactful cross-functional projects and their outcomes.",
  },
  {
    key: "marco",
    src: marco,
    name: "Marco K.",
    nameSubtitle: "Product Manager",
    text: "Bounce is raising the bar to achieve world-class operational excellency every single day while having a genuinely supportive work culture with colleagues who care about your personal and professional success.",
  },
]

export const EmployeeTestimonials = () => (
  <section className="relative mx-auto w-full max-w-screen-xl px-6">
    <TestimonialSlider className={styles.testimonials} items={TESTIMONIALS} />
  </section>
)
