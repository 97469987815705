import classNames from "classnames"
import type { StaticImageData } from "next/image"
import { Autoplay, FreeMode } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import { PNImage } from "@bounce/web-components"

import styles from "./assets/slider.module.css"

import OFFICE_1 from "./assets/office-1.jpg"
import OFFICE_2 from "./assets/office-2.jpg"
import OFFICE_3 from "./assets/office-3.jpg"
import OFFICE_4 from "./assets/office-4.jpg"
import OFFICE_5 from "./assets/office-5.jpg"
import OFFICE_6 from "./assets/office-6.jpg"
import OFFICE_7 from "./assets/office-7.jpg"
import OFFICE_8 from "./assets/office-8.jpg"
import OFFICE_9 from "./assets/office-9.jpg"

const SliderPicture = ({ src }: { src: string | StaticImageData }) => (
  <PNImage
    src={src}
    loading="lazy"
    alt="Photo of the bounce office."
    className="w-full rounded-xl bg-neutral-100 object-cover object-center"
    style={{ height: 275 }}
    height={275}
  />
)

const PicturesSlider = ({ className }: { className: string }) => (
  <Swiper
    className={classNames(className, styles.infiniteSlider)}
    spaceBetween={48}
    modules={[Autoplay, FreeMode]}
    autoplay={{ delay: 0 }}
    loop
    preventClicks={true}
    allowTouchMove={false}
    speed={10000}
    freeMode={{ enabled: true, momentum: false }}
    style={{ overflow: "visible" }}
    slidesPerView="auto">
    {[OFFICE_1, OFFICE_2, OFFICE_3, OFFICE_4, OFFICE_5, OFFICE_6, OFFICE_7, OFFICE_8, OFFICE_9].map((src, index) => (
      <SwiperSlide key={index} style={{ width: 400 }}>
        <SliderPicture src={src} />
      </SwiperSlide>
    ))}
  </Swiper>
)

export const WhereWeWork = () => {
  return (
    <>
      <div className="mx-auto flex w-full max-w-screen-xl flex-col items-center px-6 text-center text-primary-900 mt-section">
        <h2 className="title2">Where we work</h2>
        <p className="paragraph1 mt-8 max-w-3xl ">
          Founded in San Francisco, with headquarters in Lisbon and an office in Tokyo, we're all over the globe. Most
          Bouncians work together from our beautiful Lisbon office in the São Bento neighborhood.
        </p>
      </div>
      <div className="relative mt-16 w-full overflow-hidden px-6 md:mt-26 ">
        <PicturesSlider className="relative w-full overflow-visible" />
      </div>
    </>
  )
}
