import classNames from "classnames"
import Image from "next/image"

import { DateTime } from "@bounce/date"

import a16zLogo from "./assets/a16z.svg?url"
import jn from "./assets/jn.svg?url"
import tcLogo from "./assets/tech_crunch.svg?url"

import CalendarRangeIcon from "@bounce/assets/icons/CalendarRange.svg"

type PressPost = {
  title: string
  date: string
  href: string
  image?: any // nextjs StaticImport
  imageAlt?: string
  className?: string
}

const pressColors = ["bg-zest-700", "bg-lilac-100", "bg-stone-700"]

const POSTS: PressPost[] = [
  {
    title: "Bounce bags $19M to expand its traveler convenience network",
    date: DateTime.fromISO("2024-11-12T10:00:00.00Z").toLocaleStringGlobal(DateTime.DATE_MED),
    href: "https://techcrunch.com/2024/11/12/bounce-grabs-19m-to-expand-its-traveller-convenience-network/",
    image: tcLogo,
    imageAlt: "on Tech Crunch",
  },
  {
    title: "Startup norte-americana Bounce capta 19 milhões com investimento português",
    date: DateTime.fromISO("2024-11-12T07:00:00.00Z").toLocaleStringGlobal(DateTime.DATE_MED),
    href: "https://www.jornaldenegocios.pt/empresas/tecnologias/detalhe/startup-norte-americana-bounce-capta-19-milhoes-com-investimento-portugues",
    image: jn,
    imageAlt: "on Jornal de Negócios",
  },
  {
    title: "Investing in Bounce",
    date: DateTime.fromISO("2022-04-26T07:00:16.00Z").toLocaleStringGlobal(DateTime.DATE_MED),
    href: "https://a16z.com/2022/04/26/investing-in-bounce/",
    image: a16zLogo,
    imageAlt: "on Andreessen Horowitz",
  },
]

const PostCard = ({ title, date, href, image, imageAlt, className }: PressPost) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classNames(
        "group flex min-h-72 min-w-72 max-w-sm shrink-0 grow basis-72 flex-col justify-between gap-y-4 rounded-3xl px-6 py-10 text-primary-900 lg:max-w-lg",
        className,
      )}>
      {image ? <Image {...image} alt={imageAlt} /> : <div />}
      <div className="flex flex-col gap-y-2">
        <h3 className="title5 group-hover:underline">{title}</h3>
        <div className="label2 flex items-center">
          <CalendarRangeIcon className="mr-2 inline-block size-6" />
          <span>{date}</span>
        </div>
      </div>
    </a>
  )
}

export const Press = () => {
  return (
    <div className="flex w-full flex-col items-center px-8 py-20 text-primary-900 md:px-16 md:py-26">
      <h2 className="title2 mb-8 text-center lg:mb-16">Bounce in the press</h2>
      <div className="flex flex-col items-stretch justify-center gap-10 lg:flex lg:flex-row">
        {POSTS.map((p, i) => (
          <PostCard key={i} {...p} className={pressColors[i % 3]} />
        ))}
      </div>
    </div>
  )
}
