import classNames from "classnames"
import React from "react"

import { useIntercom } from "@bounce/intercom"

import { Footer } from "@/components/Generic/Footer/Footer"
import { Head } from "@/components/Generic/Head"
import type { HeadProps } from "@/components/Generic/Head"
import { Navbar } from "@/components/LuggageStorage/Navbar/Navbar"
import { config } from "@/config"

const ACTIVE_LANGUAGES = Object.values(config.locales)
  .filter(locale => locale.active)
  .map(locale => locale.language)

export type LayoutProps = {
  headProps?: HeadProps
  navBarClassName?: string
  children?: React.ReactNode
  languages?: string[]
}

export const Layout = ({ headProps, children, languages = ACTIVE_LANGUAGES, navBarClassName }: LayoutProps) => {
  useIntercom({
    appId: config.intercom.luggageStorageAppId,
    supportedEvents: [],
  })

  return (
    <>
      <Head {...headProps} languages={languages} />
      <div className={classNames("sticky top-0 z-10 bg-white", navBarClassName)}>
        <Navbar languages={languages} />
      </div>
      <main className="flex w-full flex-1 flex-col items-center">{children}</main>
      <Footer />
    </>
  )
}
