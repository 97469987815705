import classNames from "classnames"
import React from "react"

import { PNImage } from "@bounce/web-components"

import SafeLink from "../../../../../components/Generic/SafeLink/SafeLink"
import type { AshbyRoleEntry } from "../../../../../libs/ashby"

import ArrowTopRightIcon from "@bounce/assets/icons/ArrowTopRight.svg"
import ClockIcon from "@bounce/assets/icons/Clock.svg"
import LocationPinIcon from "@bounce/assets/icons/LocationPin.svg"
import CatInABox from "@bounce/assets/illustrations/cat-in-a-box@2x.webp"

type Props = {
  openRoles?: Record<string, AshbyRoleEntry[]>
  className?: string
  id?: string
}

export const OpenRoles = ({ openRoles = {}, className, id }: Props) => (
  <div className={classNames("mx-auto flex w-full min-w-min max-w-screen-xl flex-col px-6", className)} id={id}>
    <h2 className="title2 text-center text-primary-900">Open Roles</h2>
    <div className="mt-8 flex flex-col gap-y-8 md:mt-16 md:gap-y-16">
      <OpenRolesListing openRoles={openRoles} />
    </div>
  </div>
)

const OpenRolesListing = ({ openRoles = {} }: Pick<Props, "openRoles">) => {
  if (Object.keys(openRoles).length === 0) {
    return <EmptyOpenRoles />
  }

  return Object.entries(openRoles).map(([team, roles]) => <OpenRolesTeam key={team} teamName={team} roles={roles} />)
}

const EmptyOpenRoles = () => (
  <div className="flex w-full flex-col items-center text-center">
    <PNImage
      alt=""
      src={CatInABox}
      unoptimized
      loading="lazy"
      height={240}
      width={240}
      className="w-10/12 object-center sm:w-1/4"
    />
    <h5 className="title5 mb-4 mt-12 text-primary-900">No open roles</h5>
    <p className="paragraph1 text-neutral-800">There are no open roles, currently. Check back at another time.</p>
  </div>
)

const OpenRolesTeam = ({ teamName, roles }: { teamName: string; roles: AshbyRoleEntry[] }) => {
  if (!Array.isArray(roles) || roles.length === 0) return null

  return (
    <div className="w-full " key={teamName}>
      <h3 className="title5 mb-6 text-primary-900">
        {teamName} ({roles.length})
      </h3>
      <div className="flex flex-col gap-2">
        {roles.map(or => (
          <OpenRoleEntry key={or.id} {...or} />
        ))}
      </div>
    </div>
  )
}

const OpenRoleEntry = ({ title, locationName, employmentType, externalLink }: AshbyRoleEntry) => (
  <SafeLink
    href={`${externalLink}?utm_source=careers_page`}
    target="_blank"
    className="group flex flex-row items-end gap-3 rounded-xl border border-neutral-300 bg-primary-50 p-6 text-primary-900 hover:bg-primary-700 hover:text-white">
    <div className="flex flex-1 flex-col justify-stretch gap-3 md:flex-row md:items-center">
      <h4 className="title6 grow text-primary-700 group-hover:text-white">{title}</h4>
      <div className="label2 flex shrink-0 flex-col gap-2 md:basis-80 md:flex-row">
        <RoleLabel Icon={LocationPinIcon}>{locationName}</RoleLabel>
        <RoleLabel Icon={ClockIcon}>{employmentType}</RoleLabel>
      </div>
    </div>
    <span className="label1 shrink-0 md:mt-0 md:inline-flex">
      <ArrowTopRightIcon />
    </span>
  </SafeLink>
)

const RoleLabel = ({ children, Icon }: { children: React.ReactNode; Icon: any }) => (
  <div className="flex flex-row items-center gap-2 md:inline-flex md:basis-36">
    <Icon className="inline-block size-5 shrink-0 md:size-6" />
    <span className="pt-0.5">{children}</span>
  </div>
)
