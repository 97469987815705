import Image from "next/image"

import a16zLogo from "./assets/a16z.svg?url"
import generalCatalystLogo from "./assets/general-catalyst.svg?url"

const investors = [
  { image: a16zLogo, title: "Andreessen Horowitz" },
  { image: generalCatalystLogo, title: "General Catalyst" },
]

export const Investors = () => (
  <div className="flex flex-col gap-y-8 px-8 py-20 text-center text-primary-900 md:gap-y-26 md:px-16 md:py-26">
    <h2 className="title2 mb-3 md:max-w-3xl">Backed by the best investors</h2>
    <p className="paragraph1 flex flex-col justify-center gap-y-8 md:max-w-3xl md:flex-row md:gap-x-12">
      {investors.map(({ image, title }, index) => (
        <Image key={index} {...image} alt={title} className="h-11 w-auto" />
      ))}
    </p>
  </div>
)
